.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%; }

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 1rem;
  font-family: 'Arial';
  font-size: 1rem;
  font-weight: 400;
  background-image: none !important;

  &::placeholder {
    color: #7B7B7B; } }
