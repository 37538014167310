// Variables
$primary-color: #E14E0A;
$border-color: #CED4DA;
$danger-color: #dc3545;
$disabled-bg: #e9ecef;
$disabled-button: #7b7b7b;
$item-bg: #E5E5E5;
$new-item-bg: #2FFF004D;
$white: #fff;

// Mixins
@mixin focus-shadow($color) {
   box-shadow: 0 0 0 0.2rem rgba($color, 0.25); }

@mixin disabled-state {
   opacity: 0.5;
   cursor: not-allowed; }

@mixin custom-scrollbar {
   &::-webkit-scrollbar {
      width: 4px;
      height: 100%; }

   &::-webkit-scrollbar-track {
      background: transparent; }

   &::-webkit-scrollbar-thumb {
      background: rgba($primary-color, 0.25);
      border-radius: 10px; }

   &:hover {
      &::-webkit-scrollbar-thumb {
         background: rgba($primary-color, 0.8); } } }

@mixin flex-center {
   display: flex;
   align-items: center;
   justify-content: center; }

.container {
   display: flex;
   align-items: stretch;
   width: 100%;
   position: relative;
   border-radius: 5px;
   border: 1px solid $border-color;
   transition: all 0.2s ease-in-out;

   &:focus-within {
      @include focus-shadow($primary-color);
      border-color: rgba($primary-color, 0.75); }

   &.is-invalid {
      border-color: $danger-color;

      &:focus-within {
         @include focus-shadow($danger-color); } }

   &.is-disabled {
      background-color: $disabled-bg;
      @include disabled-state; } }

.input {
   border: none !important;
   box-shadow: none !important; }

.buttonContainer {
   padding: 4px;
   display: flex;
   border: none;
   align-items: center; }

.addButton {
   @include flex-center;
   height: 100%;
   width: 100%;
   background-color: $primary-color;
   color: $white;
   border-radius: 4px;
   border: none;
   transition: all 0.2s ease-in-out;

   &:hover {
      background-color: rgba($primary-color, 0.8); }
   &:focus {
      outline: none;
      @include focus-shadow($primary-color); }
   &:disabled {
      background-color: $disabled-button;
      @include disabled-state; } }

.itemsList {
   @include custom-scrollbar;
   list-style-type: none;
   max-height: 6rem;
   overflow-y: auto;
   margin: 1rem 0;
   display: flex;
   flex-wrap: wrap;
   gap: 0.5rem;
   padding: 0; }

.item {
   @include flex-center;
   gap: 0.5rem;
   padding: 0.5rem 1rem;
   border-radius: .5rem;
   border: none;
   background-color: $item-bg;
   cursor: default;
   font-size: 0.875rem;
   transition: all 0.2s ease-in-out;

   &.is-disabled {
      @include disabled-state; }

   &:not(.is-disabled).is-new {
      background-color: $new-item-bg;
      font-weight: bold; }

   & > button {
      all: unset; }

   &:not(.is-disabled):hover {
      filter: brightness(0.9); } }
